<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Uživatelé - Administrátoři - edit <span class="utils__link--underlined text-primary">#{{
            this.id
          }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body" v-if="detail !== undefined && detail !== null">
      <a-form :form="form" @submit="handleSubmit">
        <h5 class="text-black mt-2 mb-3">
          <strong>Osobní nastavení</strong>
        </h5>
        <a-form-item
          :required="true"
          label="Ikonka"
        >
          <a-form-item>
            <a-radio-group v-decorator="['avatar']" name="avatar"
                           :class="$style.gallery">
              <a-col :key="item" v-for="item in $avatarVectors" :xl="3" :md="6" :sm="8" :xs="12">
                <div class="mb-4"
                     :class="{[$style.active]: item === form.getFieldValue('avatar'), [$style.item]: 1 === 1, [$style.withControls]: 1 === 1}">
                  <cui-avatar :src="$avatarVectorPath + item" :size="110" :svg="true"/>
                  <div :class="$style.itemControls">
                    <a-radio :class="$style['ant-radio-wrapper']" :value="item"/>
                  </div>
                </div>
              </a-col>
            </a-radio-group>
          </a-form-item>
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              label="Jméno"
              :validate-status="error('uer_first_name') ? 'error' : ''"
              :help="error('uer_first_name') || ''"
            >
              <a-input
                v-decorator="['uer_first_name', { rules: [{max: 30, message: 'Jméno nemůže být delší než 30 znaků!'}, { required: true, message: 'Jméno musí být vyplněné!' }]}]"
                placeholder="Jméno"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Příjmení"
              :validate-status="error('uer_last_name') ? 'error' : ''"
              :help="error('uer_last_name') || ''"
            >
              <a-input
                v-decorator="['uer_last_name', { rules: [{max: 40, message: 'Příjmení nemůže být delší než 40 znaků!'}, { required: true, message: 'Příjmení musí být vyplněné!' }]}]"
                placeholder="Příjmení"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label="E-mailová adresa"
          :validate-status="error('email') ? 'error' : ''"
          :help="error('email') || ''"
        >
          <a-input
            type="email"
            v-decorator="['email', { rules: [{ type: 'email', message: 'E-mailová adresa není platný formát!' }, { required: true, message: 'E-mailová adresa musí být vyplněná!' }]}]"
            placeholder="E-mailová adresa"
          />
        </a-form-item>
        <h5 class="text-black mt-2 mb-3">
          <strong>Bezpečnostní nastavení</strong>
        </h5>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item
              label="Nové heslo"
              :validate-status="error('new_password') ? 'error' : ''"
              :help="error('new_password') || ''">
              <a-input
                type="password"
                v-decorator="['new_password', { rules: [{ validator: validateToNextPassword }, { required: form.getFieldValue('new_password_again') !== undefined && form.getFieldValue('new_password_again').length > 0, message: 'Nové heslo musí být vyplněné!' }, { min: 6, message: 'Nové heslo musí mít délku alespoň 6 znaků' }]}]"
                placeholder="Nové heslo"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="Nové heslo znovu"
              :validate-status="error('new_password_again') ? 'error' : ''"
              :help="error('new_password_again') || ''">
              <a-input
                type="password"
                v-decorator="['new_password_again', { rules: [{ validator: compareToFirstPassword }, { required: form.getFieldValue('new_password') !== undefined && form.getFieldValue('new_password').length > 0, message: 'Nové heslo znovu adresa musí být vyplněné!' }, { min: 6, message: 'Nové heslo znovu musí mít délku alespoň 6 znaků' }]}]"
                placeholder="Nové heslo znovu"
                @blur="handleConfirmBlur"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label="Heslo"
          :validate-status="error('password') ? 'error' : ''"
          :help="error('password') || ''">
          <a-input
            v-decorator="['password', { rules: [{ min: 6, message: 'Heslo musí mít délku alespoň 6 znaků' }, { required: true, message: 'Heslo musí být vyplněno!' }]}]"
            placeholder="Heslo"
            autocomplete="off"
            type="password"
          />
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">
            Upravit
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import CuiAvatar from '@/components/CleanUIComponents/Avatar'
import { hasErrors } from '@/services/forms'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, CuiAvatar },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('email')
    this.form.getFieldDecorator('uer_first_name')
    this.form.getFieldDecorator('uer_last_name')
  },
  data: function () {
    return {
      hasErrors,
      id: this.$route.params.id,
      confirmDirty: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/user/admin',
          title: 'Seznam',
        },
      ],
      loading: false,
    }
  },
  computed: {
    detail: function () {
      return this.$store.getters['userAdmin/getDetail']
    },
  },
  methods: {
    handleConfirmBlur(e) {
      this.confirmDirty = this.confirmDirty || !!e.target.value
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue('new_password')) {
        callback(new Error('Hesla se neshodují!'))
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      if (value && this.confirmDirty) {
        this.form.validateFields(['new_password_again'], { force: true })
      }
      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('userAdmin/put', { id: this.id, item: this.getData() })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        avatar: this.form.getFieldValue('avatar'),
        email: this.form.getFieldValue('email'),
        uer_first_name: this.form.getFieldValue('uer_first_name'),
        uer_last_name: this.form.getFieldValue('uer_last_name'),
        new_password: this.form.getFieldValue('new_password') === undefined || this.form.getFieldValue('new_password').trim().length === 0 ? null : this.form.getFieldValue('new_password'),
        password: this.form.getFieldValue('password'),
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    initDetail() {
      this.$store.dispatch('userAdmin/getOne', this.id)
        .then(() => {
          this.initForm()
        })
        .catch(() => {
        })
    },
    initForm() {
      this.form.getFieldDecorator('avatar')
      this.form.setFieldsValue({
        avatar: this.detail.avatar,
        email: this.detail.email,
        uer_first_name: this.detail.uer_first_name,
        uer_last_name: this.detail.uer_last_name,
      })
      this.form.validateFields()
    },
  },
  created() {
    this.initDetail()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.id = newId
      this.initDetail()
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
